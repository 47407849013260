<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{$t('creator.reviewPrices')}}</h1>
        <p class="avenir-medium">{{$t('creator.reviewPricesText')}}</p>

          <div v-if="!is_brandt_talent" class="d-flex justify-content-between ">
            <div class="">
              <p class="text-left avenir-medium m-0">{{$t('creator.deal')}} 1</p>
              <strong class="avenir-bold">{{$t(`dealsTitles.${steps.work_type.value}_${steps.content_type.value}`)}}</strong>
            </div>
            <b-form-group label-for="input-price" class="form-group-layout">
              <b-input-group @keyup.enter="deal.price.length > 0 ? save(): ''" prepend="$" id="input-price" :class="!statePayment(deal.price) ? 'is-invalid' : 'is-valid'">
                <component
                  :is="cleave"
                  v-model="deal.price"
                  :options="options_number"
                  :class="`form-control ${!statePayment(deal.price) ? 'is-invalid' : 'is-valid'}`"
                  :raw="false"
                />
              </b-input-group>
              <div class="invalid-feedback-deals mt-1" v-if="!statePayment(deal.price)">
                {{$t('creator.price_around_10')}}
              </div>
            </b-form-group>
          </div>
          <div v-else>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="public" stacked>
                <div class="d-flex justify-content-between">
                  <div class="mr-4">
                    <p class="text-left avenir-medium m-0">{{$t('creator.deal')}} 1</p>
                    <strong class="avenir-bold">{{$t(`dealsTitles.${steps.work_type.value}_${steps.content_type.value}`)}}</strong>
                  </div>
                  <b-form-group label-for="input-price" class="form-group-layout">
                    <b-input-group @keyup.enter="deal.price.length > 0 ? save(): ''" prepend="$" id="input-price" :class="!statePayment(deal.price) ? 'is-invalid' : 'is-valid'">
                      <component
                        :is="cleave"
                        v-model="deal.price"
                        :options="options_number"
                        :class="`form-control ${!statePayment(deal.price) ? 'is-invalid' : 'is-valid'}`"
                        :raw="false"
                      />
                    </b-input-group>
                    <div class="invalid-feedback-deals mt-1" v-if="type_price === 'public' && !statePayment(deal.price)">
                      {{$t('creator.price_around_10')}}
                    </div>
                  </b-form-group>
                </div>
              </b-form-radio>

              <b-form-radio v-model="type_price" :aria-describedby="ariaDescribedby" value="hidden" stacked class="mt-3">
                <div class="d-flex justify-content-between">
                  <div class="mr-4">
                    <p class="text-left avenir-medium m-0">Precio Escondido</p>
                    <strong class="avenir-bold">Talento Top</strong>
                  </div>
                  <b-form-group label-for="input-price" class="form-group-layout w-100">
                    <b-form-rating v-model="range_price" no-border size="lg">
                      <template #icon-empty>
                        <b-img src="@/assets/images/icons/dollar-empty.svg" class="icon-svg"/>
                      </template>
                      <template #icon-full>
                        <b-img src="@/assets/images/icons/dollar-full.svg" class="icon-svg"/>
                      </template>
                    </b-form-rating>
                  </b-form-group>
                </div>
              </b-form-radio>
            </b-form-group>
          </div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button 
            :disabled="!isPassingValidations() || loading_proposal" 
            variant="blue-button-next-step" 
            class="blue-button-next-step my-2" 
            @click="save()"
          >
          <div v-if="loading_proposal">
            <b-spinner class="spinner-waiting-service"></b-spinner>
            {{ $t('creator.upLoading') }}
          </div>
          <div v-else>
            {{ $t('creator.saveSeePreview') }}
          </div>
        </b-button>
        </b-col>
        <small class="mt-2 w-100 text-center text-muted">{{$t('creator.price10min')}} USD</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BSpinner,
  BFormRadio,
  BFormRating,
  BImg
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';

import { getUserData } from '../../../../libs/utils/user';

export default {
  name: 'deals',
  components: {
    BRow,   
    BCol,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BSpinner,
    BFormRadio,
    BFormRating,
    BImg
  },
  data() {
    return {
      steps: null,
      deal: 
        {
          price: 10,          
        },
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      proposal: {},
      cleave: null,
      loading_proposal: false,
      is_brandt_talent: false,
      range_price: null,
      type_price: 'public'
    }
  },
  created() {
    this.getData();
    this.checkBrandmeTalent();
  },
  async mounted() {
    this.cleave = (await import('vue-cleave-component')).default;
  },
  methods: {
    checkBrandmeTalent() {
      const user = getUserData()
      const permissions = JSON.parse(localStorage.getItem('permissions'))
      const has_permission_brandme_talent = permissions.indexOf('brandme_talent')
      this.is_brandt_talent = user.is_superuser || has_permission_brandme_talent > -1
    },
    statePayment(price) {
      const real_price = typeof price === 'string' ? parseInt(price.replace(/,/g, '')) : price
      return real_price >= 10 && real_price < 9999999
    },
    getData() {      
      this.steps = JSON.parse(localStorage.getItem('steps'));
    },
    getFormatProposal(steps) {
      const data = {
        work_type: steps.work_type.value,
        content_type: steps.content_type.value,
        user_network_uuid: steps.network_selected.uuid,
        deals: [
          {
            price: this.deal.price,
            title: this.$t(`dealsTitles.${this.steps.work_type.value}_${this.steps.content_type.value}`),
            description: this.$t('creator.anyDescription'),
            features: ['share_your_content', 'create_custom_content', 'ship_me_your_product', 'brand_mention', 'product_placement'],
            days_delivery: 15,
            revision: 2,
            licensing: 'sponsored_social_content'
          }
        ],
      }

      if (this.type_price === 'hidden') {
        data.deals[0].range_price = this.range_price;
        data.deals[0].price = 0;
      }

      return data
    },
    save() {
      if (this.isPassingValidations()) {
        this.loading_proposal = true;
        if (isNaN(this.deal.price)) this.deal.price = parseInt(this.deal.price.split(',').join(''));

        const data = this.getFormatProposal(this.steps)
        proposals_services.createProposal(data).then((response) => {
          this.loaidng_proposal = false;
          this.$router.push({name: 'view_proposal', params: {slug: response.slug, load_ia: true}});
          this.$root.$emit('quit_navbar_white');
          localStorage.removeItem('steps');
        });
      }
    },
    isPassingValidations() {
      const is_passing_all = [];

      if (this.type_price === 'public') {
        is_passing_all.push(this.statePayment(this.deal.price))
        return is_passing_all.every((item) => item === true);
      } else {
        return this.range_price > 0
      }
    },
  },
  watch: {
    type_price(new_val) {
      if (new_val === 'public') {
        this.range_price = null;
      }
    },
    range_price() {
      this.type_price = 'hidden'
    }
  }
}
</script>
<style scoped>
.icon-svg {
  width: 20px;
}
.invalid-feedback-deals {
  font-size: 0.857rem !important;
  color: #ea5455 !important;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
  margin-right: 0.55em;
}
</style>
<style>

.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
  /* text-align: left; */
}

</style>